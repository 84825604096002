























import { Component, Prop, Vue } from 'vue-property-decorator'

import { OrderStatus, OrderStatusResource } from '@/store/types'

@Component
export default class SystemMessageCard extends Vue {
  @Prop({ required: true })
  private orderInfo!: OrderStatusResource | null

  @Prop({ default: false })
  private isAuth!: boolean

  @Prop({ default: true })
  private onlyCard!: boolean

  private get title() {
    if (this.orderInfo?.status !== OrderStatus.PAID) {
      return 'Упс, здесь ничего нет'
    }
    return !this.orderInfo.total ? 'Получен доступ к курсу' : 'Оплата прошла успешно'
  }

  private get type() {
    return this.orderInfo?.status === OrderStatus.PAID ? 'success' : 'error'
  }
}
