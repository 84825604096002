















import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

// components
import SystemMessageCard from '@/components/cards/SystemMessageCard.vue'
import CourseStoreCard from '@/components/cards/CourseStoreCard.vue'
// store
import MasterCoursesModule from '@/store/modules/master/courses'
// types
import { MasterRecommendationResource } from '@/store/types'

@Component({
  components: {
    SystemMessageCard,
    CourseStoreCard,
  },
})
export default class Success extends Vue {
  private recommendedCourses: MasterRecommendationResource[] = []

  private get courseID() {
    return Number(localStorage.getItem('lastOrderCourseID'))
  }

  private mounted() {
    this.fetchRecommendations(this.courseID)
  }

  private fetchRecommendations(courseID: number) {
    if(!courseID) return

    MasterCoursesModule.fetchRecommendations(courseID)
      .then(response => {
        this.recommendedCourses = response
        localStorage.removeItem('lastOrderCourseID')
      })
  }

  private hardcodeCourses = [
    {
      id: 1,
      annotation: '12345',
      subjectId: 2,
      originalPrice: 1999,
      price: 1099,
      hasDiscount: true,
      educationEnd: '2022-07-31 00:00:00',
      educationStart: '2022-07-17 00:00:00',
      teachers: [
        {
          name: 'Эля',
          surname: 'Смит',
        },
      ],
      groups: [
        {
          id: 1,
          type: {
            name: 'МГ Стандарт',
            value: 'default',
          },
        },
      ],
      title: '1234567890',
      type: {
        name: 'Мастер-группа',
        value: 'defult',
      },
    },
    {
      id: 2,
      annotation: '12345',
      subjectId: 2,
      originalPrice: 1999,
      price: 1099,
      hasDiscount: true,
      educationEnd: '2022-07-31 00:00:00',
      educationStart: '2022-07-17 00:00:00',
      teachers: [
        {
          name: 'Эля',
          surname: 'Смит',
        },
      ],
      groups: [
        {
          id: 1,
          type: {
            name: 'МГ Стандарт',
            value: 'default',
          },
        },
      ],
      title: '1234567890',
      type: {
        name: 'Мастер-группа',
        value: 'defult',
      },
    },
    {
      id: 3,
      annotation: '12345',
      subjectId: 2,
      originalPrice: 1999,
      price: 1099,
      hasDiscount: true,
      educationEnd: '2022-07-31 00:00:00',
      educationStart: '2022-07-17 00:00:00',
      teachers: [
        {
          name: 'Эля',
          surname: 'Смит',
        },
      ],
      groups: [
        {
          id: 1,
          type: {
            name: 'МГ Стандарт',
            value: 'default',
          },
        },
      ],
      title: '1234567890',
      type: {
        name: 'Мастер-группа',
        value: 'defult',
      },
    },
    {
      id: 4,
      annotation: '12345',
      subjectId: 2,
      originalPrice: 1999,
      price: 1099,
      hasDiscount: true,
      educationEnd: '2022-07-31 00:00:00',
      educationStart: '2022-07-17 00:00:00',
      teachers: [
        {
          name: 'Эля',
          surname: 'Смит',
        },
      ],
      groups: [
        {
          id: 1,
          type: {
            name: 'МГ Стандарт',
            value: 'default',
          },
        },
      ],
      title: '1234567890',
      type: {
        name: 'Мастер-группа',
        value: 'defult',
      },
    },
  ]

  private metaInfo (): MetaInfo {
    return {
      title: 'Оплата прошла успешно',
    }
  }
}
