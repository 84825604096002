




































import { Component, Prop, Vue } from 'vue-property-decorator'

// types
import { CourseType, MasterCourseShortResource } from '@/store/types'
// utils
import { subjectsListOnID, thumbs } from '@/utils/constants'

@Component
export default class CourseStoreCard extends Vue {
  @Prop({ required: true })
  private course!: MasterCourseShortResource

  private thumbs = thumbs

  private get isSpecial() {
    return this.course.type.value === CourseType.SPECIAL
  }

  private get subjectSlug() {
    return subjectsListOnID[this.course.subjectId]
  }
}
